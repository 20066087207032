import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle65 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Van der Vlist kiest voor Delta Refund Solutions

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage65.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                07-03-25
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            Van der Vlist{" "}
            </span>
            is een logistieke dienstverlener, gedreven door een passie voor transport en techniek. Met inzet van informatie- en 
            communicatietechnologie en op basis van integriteit en transparantie is Van der Vlist voortdurend op zoek naar voor 
            de klant optimale en duurzame oplossingen. Kernwoorden die we daarbij hanteren zijn commitment (toewijding), compassion 
            (mededogen) en perseverence (volharding). Onderscheidend willen we zijn op de aspecten van veiligheid, rentmeesterschap 
            (duurzaamheid), kwaliteit, informatie- en communicatie, kennis van wet- en regelgeving en met onze mensen (personeel). Als{" "}
            <span style={{color: "#2b5baa"}}>
            familiebedrijf {" "}
            </span>
            hecht Van der Vlist grote waarde aan continuïteit. Niet alleen continuïteit van het bedrijf, maar zeker ook van de relaties die we hebben met alle belanghebbenden zoals personeel, klanten en leveranciers. We streven naar een{" "}
            <span style={{color: "#9c29ff"}}>
            lange termijn samenwerking met betrouwbare partners{" "}
            </span>
            die passen bij onze kernwaarden en visie. {" "}
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
           In het verleden heeft Van der Vlist lang samengewerkt met Bert en Ruud. Helaas stopte deze samenwerking na overname van het bedrijf waaraan zij toen leidinggaven. Van der Vlist gaat nu de {" "}
            <span style={{color: "#2b5baa"}}>
            samenwerking aan met Delta Refund Solutions{""}
            </span>
            , omdat we ervan overtuigd zijn dat we bij elkaar passen en elkaar versterken. 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
           <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Delta Refunds Solutions is een{" "}
                        <span style={{color: "#2b5baa"}}>
                        familiebedrijf{" "}
                        </span>
                        waaraan Bert en Ruud zich verbonden hebben, zodat de volgende generatie zich kan voorbereiden op 
                        het voortzetting daarvan. Deze lange termijn visie op samenwerking en continuiteit staat ons vanzelfsprekend heel erg aan
                        </li>
                        <li>   
                        Delta Refunds Solutions is een bedrijf waarin de {" "}
                        <span style={{color: "#2b5baa"}}>
                        commitment, compassion en perseverence  {" "}
                        </span>
                        merkbaar zijn. We weten uit ervaring dat Bert en Ruud met grote toewijding voor ons de teruggaven verzorgen, niet opgeven 
                        als het moeilijk is en daarbij oog houden voor de menselijke maat en de relatie. Dat is ook merkbaar in de communicatie. Snel, adequaat en prettig
                        </li>
                        <li>   
                        Delta Refunds Solutions staat ervoor open om samen te werken aan het {" "}
                        <span style={{color: "#2b5baa"}}>
                        verbeteren van de processen {" "}
                        </span>
                        voor Van der Vlist. Er zal dus gezamenlijk gewerkt worden aan het toepassen van informatie en communicatietechnologie gericht op 
                        (het gezamenlijke belang) van efficiency en kwaliteit 
                        </li>
                        <li>   
                        Afspraken met Delta Refunds Solutions staan vast. Daarin kennen wij Bert en Ruud als “een man een man, een woord een woord”. Daarin vinden wij aansluiting als het gaat om{" "}
                        <span style={{color: "#2b5baa"}}>
                        “integriteit en transparantie”
                        </span>
                        </li>
                        <li>   
                        Tenslotte, en zeker niet onbelangrijk waarderen wij de {" "}
                        <span style={{color: "#2b5baa"}}>
                        kennis en kunde {" "}
                        </span>
                        die nodig is om wet- en regelgeving correct toe te passen
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Kortom, wij zijn ervan overtuigd dat we met Delta Refunds Solutions een lange termijn relatie kunnen opbouwen omdat we verschillende waarden en visies delen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het samenwerken met Delta Refunds Solutions heeft een aantal praktische voordelen: 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Een klantenportaal met al onze wensen
                        </li>
                        <li>   
                        Integratie van systemen op het niveau dat het beste past
                        </li>
                        <li>   
                        Directe samenwerking (contactpersonen)
                        </li>
                        <li>   
                        Een fair tarief, dat wil zeggen concurrerend
                        </li>
                        <li>   
                        De mogelijkheid om samen verder te ontwikkelen
                        </li>
                        <li>   
                        Groot genoeg om zaken voor elkaar te krijgen. Niet te groot, waardoor direct contact en snelle actie mogelijk is
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <span style={{color: "#9c29ff"}}>
             De overstap is heel soepel gegaan {" "}
            </span>  
            doordat we door Delta Refund Solutions aan de hand zijn genomen gedurende de gehele onboarding.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het laatste zetje om over te stappen was een goed gesprek met Ruud waarin we met elkaar onze visie op de toekomstige samenwerking hebben gedeeld en 
            waarin duidelijk werd dat ook Delta Refund Solutions een{" "}
            <span style={{color: "#9c29ff"}}>
            lange termijn visie{" "}
            </span>  
            heeft om een familiebedrijf te zijn en te blijven. Dan is er sprake van een herkennen van kernwaarden. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle65;
