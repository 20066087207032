import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle64 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Zweden introduceert CO2 gebaseerd tolsysteem 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage64.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                28-02-25
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Zweden zal op  {" "}
            <span style={{color: "#2b5baa"}}>
            25 maart 2025{" "}
            </span>
            een nieuw{" "}
            <span style={{color: "#2b5baa"}}>
            tolheffingssysteem {" "}
            </span>
            invoeren, waarbij naast de bestaande Euro-emissieklassen ook heffingen worden gebaseerd op{" "}
            <span style={{color: "#2b5baa"}}>
            CO₂-uitstoot
            </span>
            , meldt de lokale pers. Het herziene systeem zal van toepassing zijn op zware vrachtwagens van meer dan{" "}
            <span style={{color: "#2b5baa"}}>
            12 ton
            </span>
            , waarbij de tarieven worden bepaald op basis van zowel de Euro-emissieklasse als de CO₂-efficiëntie. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voertuigen worden ingedeeld in{" "}
            <span style={{color: "#9c29ff"}}>
            vijf CO₂-klassen
            </span>
            , waarbij vrachtwagens met een lagere uitstoot profiteren van lagere heffingen. De emissies worden beoordeeld met behulp van Vecto (Vehicle Energy Consumption Calculation Tool), 
            een EU-standaard die ook wordt gebruikt in Duitsland en Denemarken. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Volgens een rapport van de Zweedse transportnieuwssite Trailer.se zullen de nieuwe toltarieven variëren op basis van het  {" "}
            <span style={{color: "#9c29ff"}}>
            aantal assen{" "}
            </span>
            en de {" "}
            <span style={{color: "#9c29ff"}}>
            CO₂-classificatie{" "}
            </span>
            (Euro 6-vrachtwagens worden standaard ingedeeld in CO₂-klasse 1).
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor voertuigen met{" "}
            <span style={{color: "#2b5baa"}}>
            maximaal drie assen:
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
          <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Een Euro 6-vrachtwagen in{" "}
                        <span style={{color: "#2b5baa"}}>
                        CO₂-klasse 1{" "}
                        </span>
                        wordt belast met {" "}
                        <span style={{color: "#2b5baa"}}>
                        € 770{" "}
                        </span>
                        per jaar of{" "}
                        <span style={{color: "#2b5baa"}}>
                        € 8{" "}
                        </span>
                        per dag
                        </li>
                        <li>   
                        Hetzelfde voertuig in  {" "}
                        <span style={{color: "#2b5baa"}}>
                        CO₂-klasse 5 {" "}
                        </span>
                        wordt belast met{" "}
                        <span style={{color: "#2b5baa"}}>
                        € 192 {" "}
                        </span>
                        per jaar of{" "}
                        <span style={{color: "#2b5baa"}}>
                        € 2 {" "}
                        </span>
                        per dag
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor voertuigen met{" "}
            <span style={{color: "#9c29ff"}}>
            vier of meer {" "}
            </span>
            assen:
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Een Euro 6-vrachtwagen in{" "}
                        <span style={{color: "#9c29ff"}}>
                        CO₂-klasse 1{" "}
                        </span>
                        wordt belast met {" "}
                        <span style={{color: "#9c29ff"}}>
                        € 1.284{" "}
                        </span>
                        per jaar of{" "}
                        <span style={{color: "#9c29ff"}}>
                        € 13{" "}
                        </span>
                        per dag
                        </li>
                        <li>   
                        Hetzelfde voertuig in  {" "}
                        <span style={{color: "#9c29ff"}}>
                        CO₂-klasse 5 {" "}
                        </span>
                        wordt belast met{" "}
                        <span style={{color: "#9c29ff"}}>
                        € 321 {" "}
                        </span>
                        per jaar of{" "}
                        <span style={{color: "#9c29ff"}}>
                        € 4 {" "}
                        </span>
                        per dag
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het huidige jaarlijkse toltarief voor een Euro 6-vrachtwagen bedraagt €1.290. Onder het nieuwe systeem zullen 
            vrachtwagens in CO₂-klasse 1 €1.284 per jaar betalen. Voertuigen met een hoger brandstofrendement en lagere 
            emissies komen in aanmerking voor aanzienlijke kortingen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoewel de standaardtarieven voor veel vrachtwagens ongewijzigd blijven, zullen exploitanten met zuinigere voertuigen 
            profiteren van lagere toltarieven. Het systeem is ingezet om{" "}
            <span style={{color: "#2b5baa"}}>
            investeringen{" "}
            </span>
            in voertuigen met een lagere uitstoot te{" "}
            <span style={{color: "#2b5baa"}}>
            stimuleren
            </span>
            , in plaats van een algemene tolverhoging op te leggen. Oudere of minder efficiënte vrachtwagens kunnen afhankelijk van de classificatie dezelfde of iets hogere kosten krijgen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle64;
