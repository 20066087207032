import React from "react";
import NewsArticle65 from "../elements/NewsArticle65";
import NewsNav from "../elements/NewsNav";

const NewsDetails65 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle65 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails65;