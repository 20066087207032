import React from "react";
import NewsArticle64 from "../elements/NewsArticle64";
import NewsNav from "../elements/NewsNav";

const NewsDetails64 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle64 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails64;