import { Box, Divider, Grid, Stack } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import NewsCard from "./NewsCard";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
// import { TfiSearch } from "react-icons/tfi";

// import { Category } from "@mui/icons-material";

const NewsGrid = () => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedCategory, setSelectedCategory] =
    React.useState("Alles weergeven");
  // const [toggleSearchBox, setToggleSearchBox] = React.useState(false);

  const [newsItems] = React.useState([
    {
      category: "Delta nieuws",
      publishingDate: "07.03.25",
      imageUrl: require("../../assets/NewsImage65.png"),
      headerText: "Van der Vlist kiest voor Delta Refund Solutions",
      bodyText:
        "Wij van Team Delta waarderen lange termijn samenwerkingen. Wij zijn verheugd om mede te delen de hand te hebben mogen schudden met Van der Vlist, een logistieke dienstverlener met dezelfde normen en waarden.",
      navTo: "/article65",
    },   {
      category: "Algemeen transport",
      publishingDate: "28.02.25",
      imageUrl: require("../../assets/NewsImage64.png"),
      headerText: "Zweden introduceert CO2 gebaseerd tolsysteem",
      bodyText:
        "Zweden zal op 25 maart 2025 een nieuw tolheffingssysteem invoeren, waarbij naast de bestaande Euro-emissieklassen ook heffingen worden gebaseerd op CO₂-uitstoot, meldt de lokale pers. Het herziene systeem zal van toepassing zijn op...",
      navTo: "/article64",
    },
    {
      category: "Algemeen transport",
      publishingDate: "28.02.25",
      imageUrl: require("../../assets/NewsImage64.png"),
      headerText: "Zweden introduceert CO2 gebaseerd tolsysteem",
      bodyText:
        "Zweden zal op 25 maart 2025 een nieuw tolheffingssysteem invoeren, waarbij naast de bestaande Euro-emissieklassen ook heffingen worden gebaseerd op CO₂-uitstoot, meldt de lokale pers. Het herziene systeem zal van toepassing zijn op...",
      navTo: "/article64",
    },
    {
      category: "Algemeen transport",
      publishingDate: "16.01.25",
      imageUrl: require("../../assets/NewsImage63.png"),
      headerText: "Brennerpas verbiedt trucks op zaterdagen",
      bodyText:
        "Voor voertuigen met een gewicht van meer dan 7,5 ton geldt vanaf nu tot 15 maart een aanvullend rijverbod op de A12 (Inntal Autobahn), A13 (Brennerpas) en A14 (Rheindal/Walgau).",
      navTo: "/article63",
    },
    {
      category: "BTW & accijns",
      publishingDate: "20.12.24",
      imageUrl: require("../../assets/NewsImage62.png"),
      headerText: "Verlaging van toltarief in Denemarken ",
      bodyText:
        "De Deense toltarieven zullen met 19% verlagen ten opzichte van de eerder aangekondigde bedragen. Denemarken blijft momenteel een van de laatste landen die nog gebruik maakt van het Eurovignet. Echter, vanaf 1 januari stappen de Denen over...",
      navTo: "/article62",
    },
    {
      category: "BTW & accijns",
      publishingDate: "05.12.24",
      imageUrl: require("../../assets/NewsImage61.png"),
      headerText: "BTW en accijns terugvorderbaar op rode diesel?",
      bodyText:
        "Rode diesel, goed te herkennen aan zijn rode kleur, is een vorm van brandstof die voornamelijk wordt gebruikt in agrarische, maritieme en industriële sectoren. Een veelgestelde vraag is of de BTW en accijns op de rode diesel terugvorderbaar zijn.",
      navTo: "/article61",
    },
    {
      category: "Algemeen transport",
      publishingDate: "21.11.24",
      imageUrl: require("../../assets/NewsImage60.png"),
      headerText: "Mobiele grenscontroles in Nederland ",
      bodyText:
        "De Koninklijke Marechaussee start op maandag 9 december met de door het kabinet aangekondigde grenscontroles, aldus asielminister Marjolein Faber. Deze tijdelijke maatregel, die zes maanden zal duren,  is gericht op het tegengaan van...",
      navTo: "/article60",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "08.11.24",
      imageUrl: require("../../assets/NewsImage59.png"),
      headerText: "Nieuwe verplichte voertuigverklaring Scandinavië",
      bodyText:
        "Voor internationale transporteurs die naar Scandinavië vervoeren is het essentieel om op de hoogte te zijn van de laatste veranderingen in de voertuigverklaring vereisten. Vanaf heden moet het nieuwe Skyttel PAN nummer zichtbaar zijn op...",
      navTo: "/article59",
    },
    {
      category: "BTW & accijns",
      publishingDate: "17.10.24",
      imageUrl: require("../../assets/NewsImage58.png"),
      headerText: "Halfjaarlijkse wagenpark update voor Belgische accijns teruggaaf ",
      bodyText:
        "Om in aanmerking te komen voor gedeeltelijke teruggaaf van accijns op diesel dient u zich te houden aan de voorschriften uit de handleiding 'professionele diesel'. Een van de vereisten is het tijdig doorgeven van wijzigingen in uw wagenpark.",
      navTo: "/article58",
    },
    {
      category: "Algemeen transport",
      publishingDate: "03.10.24",
      imageUrl: require("../../assets/NewsImage57.png"),
      headerText: "Tariefverhoging Waalse tol per 2025",
      bodyText:
        "Wallonië voert per 1 januari 2025 een verhoging door in de tarieven voor de kilometerheffing voor vrachtwagens. Vanaf 2025 betalen vrachtwagens met een toegelaten maximaal gewicht van 3,5 tot 12 ton tussen...",
      navTo: "/article57",
    },
    {
      category: "BTW & accijns",
      publishingDate: "12.09.24",
      imageUrl: require("../../assets/NewsImage56.png"),
      headerText: "Problemen met Øresund brug BTW teruggaaf",
      bodyText:
        "De tol die u betaalt over deze Øresund brug is met BTW belast, hetgeen impliceert dat u de BTW als BTW plichtig bedrijf terug kan vorderen. In beginsel is het niet altijd helder of de betaalde BTW de Deense of de Zweedse is.  ",
      navTo: "/article56",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "29.08.24",
      imageUrl: require("../../assets/NewsImage55.png"),
      headerText: "Introductie vrachtwagenheffing in Nederland",
      bodyText:
        "In 2026 introduceert Nederland een vrachtwagenheffing, met de 'Wet vrachtwagenheffing' als juridische basis. Vanaf dan moet elke vrachtwagen die in Nederland wil rijden uitgerust zijn met werkende boordapparatuur.",
      navTo: "/article55",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "15.08.24",
      imageUrl: require("../../assets/NewsImage54.png"),
      headerText: "Tolheffing binnenkort in Denemarken ",
      bodyText:
        "Vanaf 1 januari 2025 voert Denemarken een kilometerheffing in gebaseerd op de gereden afstand en de CO2-uitstoot. De nieuwe heffing vervangt het huidige Eurovignet-systeem.",
      navTo: "/article54",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "10.07.24",
      imageUrl: require("../../assets/NewsImage53.png"),
      headerText: "Tarieven Duitse Maut voor 3,5 ton+ voertuigen",
      bodyText:
        "Vanaf 1 juli 2024 zijn ook voertuigen met een technisch toegestane max massa (F.1) van meer dan 3,5 ton en minder dan 7,5 ton, die bestemd of gebruikt zijn voor goederenvervoer over de weg, tol plichtig in Duitsland. ",
      navTo: "/article53",
    },
    {
      category: "BTW & accijns",
      publishingDate: "20.06.24",
      imageUrl: require("../../assets/NewsImage52x0,5.png"),
      headerText: "Franse dieselolieaccijns teruggaaf sneller dan ooit ",
      bodyText:
        "Bent u een internationale transporteur? Dan let u waarschijnlijk goed op de teruggaaf van Belgische dieselolieaccijns. Maar houdt u ook de Franse dieselolieaccijns in de gaten? ",
      navTo: "/article52",
    },
    {
      category: "Algemeen transport",
      publishingDate: "30.05.24",
      imageUrl: require("../../assets/NewsImage51x0,5.png"),
      headerText: " Vergelijk van alternatieve brandstoffen",
      bodyText:
        "Diesel, dat jarenlang de ruggengraat van de transportsector vormde, komt steeds meer onder vuur te liggen vanwege de negatieve impact op het milieu. Wat is op dit moment en in de toekomst het beste alternatief?",
      navTo: "/article51",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "08.05.24",
      imageUrl: require("../../assets/NewsImage50x0,5.png"),
      headerText: "EU rolt CO2-tax op tol verder uit ",
      bodyText:
        "Eind vorig jaar implementeerde Duitsland CO2-gebaseerde wegen tol voor vrachtwagens. Een aantal andere Europese landen hebben sindsdien soortgelijke tolheffingen ingevoerd in overeenstemming met de EU-wetgeving.   ",
      navTo: "/article50",
    },
    {
      category: "BTW & accijns",
      publishingDate: "25.04.24",
      imageUrl: require("../../assets/NewsImage49x0,5.png"),
      headerText: "Aanscherping regels Belgische BTW teruggaaf ",
      bodyText:
        "Recentelijk hebben we gemerkt dat er strengere controles worden uitgevoerd op BTW-teruggaaf dossiers, vooral met betrekking tot de Belgische BTW. Hierbij worden enkele regels strenger toegepast... ",
      navTo: "/article49",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "28.03.24",
      imageUrl: require("../../assets/NewsImage48x0,5.png"),
      headerText: "Duitse Maut verplicht vanaf 3,5 ton ",
      bodyText:
        "Vanaf 1 juli 2024 wordt in Duitsland de tolplicht ingevoerd voor voertuigen met een technisch toelaatbare maximummassa van meer dan 3,5 ton. Deze tol is van toepassing op voertuigen die specifiek bedoeld zijn voor goederenvervoer.",
      navTo: "/article48",
    },
    {
      category: "Algemeen transport",
      publishingDate: "15.03.24",
      imageUrl: require("../../assets/NewsImage47x0,5.png"),
      headerText: "Geen uitbreiding van tolwegennetwerk in België",
      bodyText:
        "Transportbedrijven in België en over de hele wereld keken de afgelopen periode met bezorgdheid naar de plannen van de Vlaamse regering om het wegennet uit te breiden en kilometerheffing in te voeren. Dankzij een sterke lobby werd dit...",
      navTo: "/article47",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "07.03.24",
      imageUrl: require("../../assets/NewsImage46.png"),
      headerText: "EU kondigt nieuwe Euro-7 emissienormen aan",
      bodyText:
        "De Europese wetgevers hebben overeenstemming bereikt over de komende Euro 7-normen. De Euro 7-normen zullen de huidige Euro 6-normen vervangen en strengere limieten stellen aan de uitstoot van fijnstoffen en stikstofoxiden.",
      navTo: "/article46",
    },
    {
      category: "Algemeen transport",
      publishingDate: "29.02.24",
      imageUrl: require("../../assets/NewsImage45.png"),
      headerText: "EU-rijbewijs toegankelijker voor jonge chauffeurs   ",
      bodyText:
        "Op 28 februari heeft het Europees Parlement zijn rapport over de rijbewijsrichtlijn aangenomen, waarbij belangrijke hervormingen zijn overeengekomen om cruciale obstakels voor de toegang tot het beroep van chauffeur weg te nemen.  ",
      newsId: "",
      navTo: "/article45",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "22.02.24",
      imageUrl: require("../../assets/NewsImage44.png"),
      headerText: "ESTA zwaar transport overbrugt nieuwe hindernis  ",
      bodyText:
        "De langlopende inspanningen van ESTA om de regelgeving voor zwaar transport en abnormale ladingen binnen de Europese Unie te hervormen en te harmoniseren, hebben recent een belangrijke mijlpaal bereikt. ",
      newsId: "",
      navTo: "/article44",
    },
    {
      category: "Algemeen transport",
      publishingDate: "15.02.24",
      imageUrl: require("../../assets/NewsImage43.png"),
      headerText: "Grote rechtszaak tegen Duits tolbeheer ",
      bodyText:
        "De transportvereniging Camion Pro eV heeft een petitie ingediend tegen Toll Collect GmbH, de exploitant van het Duitse tolsysteem, vanwege vermeend misbruik van haar dominante marktpositie. ",
      newsId: "",
      navTo: "/article43",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "08.02.24",
      imageUrl: require("../../assets/NewsImage42.png"),
      headerText: "Duitsland trekt steun in voor EU-klimaatregels ",
      bodyText:
        "De strengere EU-voorschriften voor de CO2-uitstoot van vrachtwagens en bussen staan op losse schroeven nu Duitsland haar steun intrekt, ondanks een eerder voorlopig akkoord binnen de EU. ",
      newsId: "",
      navTo: "/article42",
    },
    {
      category: "BTW & accijns",
      publishingDate: "01.02.24",
      imageUrl: require("../../assets/NewsImage41.png"),
      headerText: "Tariefstijging van tol in Frankrijk",
      bodyText:
        "De Franse tolwegexploitanten zullen naar verwachting op 1 februari 2024 de prijzen verhogen. Voormalig minister van Transport, Clément Beaune, beloofde vorig jaar dat de 'péage' (tol) verhogingen in 2024...",
      newsId: "",
      navTo: "/article41",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "25.01.24",
      imageUrl: require("../../assets/NewsImage40.png"),
      headerText: "Tsjechië introduceert CO2 toeslag op tol ",
      bodyText:
        "Vanaf 1 maart 2024 zal het elektronische tolsysteem in Tsjechië een nieuw heffingscomponent introduceren voor zware voertuigen, de hoogte van de CO2-uitstoot. De nieuwe tarieven zullen worden gebaseerd op...",
      newsId: "",
      navTo: "/article40",
    },
    {
      category: "BTW & accijns",
      publishingDate: "18.01.24",
      imageUrl: require("../../assets/NewsImage39.png"),
      headerText: "Verhoging van toltarieven in Polen",
      bodyText:
        "Per 1 januari 2024 zijn de toltarieven op Poolse nationale tolwegen voor zware voertuigen, bekend als de elektronische tol, aanzienlijk gestegen. De stijgingen kunnen oplopen tot 16%",
      newsId: "",
      navTo: "/article39",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "11.01.24",
      imageUrl: require("../../assets/NewsImage38.png"),
      headerText: "Risico op boetes door uitbreiding van de LEZ in GB",
      bodyText:
        "In de loop van vorig jaar zijn er diverse Low Emission Zones geïmplementeerd en verder uitgebreid in Groot Brittannië. Voertuigen die niet voldoen aan bepaalde uitstootnorm zijn verplicht...",
      newsId: "",
      navTo: "/article38",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "21.12.23",
      imageUrl: require("../../assets/NewsImage37.png"),
      headerText: "Euro 7: Akkoord over strengere regels rondom uitstoot",
      bodyText:
        "Het Parlement en de Raad heeft een voorlopig akkoord bereikt over nieuwe regels om de emissies van personenauto's, bestelwagens, bussen, vrachtwagens en aanhangwagens terug te dringen. ",
      newsId: "",
      navTo: "/article37",
    },
    {
      category: "BTW & accijns",
      publishingDate: "07.12.23",
      imageUrl: require("../../assets/NewsImage36.png"),
      headerText: "Verhoging van BTW-percentage in Luxemburg",
      bodyText:
        "De tijdelijke wet die op 26 oktober 2022 aangenomen werd door de Luxemburgse overheid die als gevolg had dat de BTW-tarieven met 1% zouden dalen, zal per 31 december 2023 niet langer van kracht zijn. ",
      newsId: "",
      navTo: "/article36",
    },
    {
      category: "BTW & accijns",
      publishingDate: "30.11.23",
      imageUrl: require("../../assets/NewsImage35.png"),
      headerText: "Verhoging van CO2 toeslag in Oostenrijk vanaf 2024",
      bodyText:
        "De Oostenrijkse tol zal vanaf 1 januari 2024 stijgen als het land de nieuwe CO2-heffingen invoert. Hoeveel deze toeslag is hangt af van de Euro-klasse van het desbetreffende voertuig. ",
      newsId: "",
      navTo: "/article35",
    },
    {
      category: "BTW & accijns",
      publishingDate: "23.11.23",
      imageUrl: require("../../assets/NewsImage34.png"),
      headerText: "Wel of geen BTW over tol?",
      bodyText:
        "Op het gebied van tolnetwerken kunnen we twee typen onderscheiden: tol gefaciliteerd door de overheid (geen BTW) en tol gefaciliteerd door private beheerders (wel BTW).",
      newsId: "",
      navTo: "/article34",
    },
    {
      category: "BTW & accijns",
      publishingDate: "16.11.23",
      imageUrl: require("../../assets/NewsImage33.png"),
      headerText: "Naderende deadline voor BTW-teruggaafverzoeken in het Verenigd Koninkrijk ",
      bodyText:
        "De deadline van 31 december voor de BTW-teruggaafverzoeken in het Verenigd Koninkrijk nadert snel voor bedrijven die niet in het Verenigd Koninkrijk zijn gevestigd.",
      newsId: "",
      navTo: "/article33",
    },
    {
      category: "Algemeen transport",
      publishingDate: "09.11.23",
      imageUrl: require("../../assets/NewsImage32.png"),
      headerText: "Tachograafkaart volgend jaar nog duurder",
      bodyText:
        "De tachograafkaarten worden steeds duurder. Een nieuwe nodig? Alleen Kiwa mag deze kaarten uitgeven. Naar verwachting zal per 2024 de prijs nogmaals stijgen met zo'n 6%.",
      newsId: "",
      navTo: "/article32",
    },
    {
      category: "Algemeen transport",
      publishingDate: "02.11.23",
      imageUrl: require("../../assets/NewsImage31.png"),
      headerText: "Een Sector in Transitie: Het verdwijnen van de middelgrote vervoerders",
      bodyText:
        "In het voorbije decennium is er een aanzienlijk aantal faillissementen en overnames in de transportsector waargenomen, waarbij de grote spelers hun kleinere (middelgrote) concurrenten hebben overgenomen.",
      newsId: "",
      navTo: "/article31",
    },
    {
      category: "BTW & accijns",
      publishingDate: "26.10.23",
      imageUrl: require("../../assets/NewsImage30.png"),
      headerText: "Mogelijke oplossing voor liquiditeitskrapte in de transportsector ",
      bodyText:
        "De transportsector staat voor een financiële uitdaging, met prijsdruk en toenemende investeringsbehoeften, wat liquiditeitsproblemen veroorzaakt. Intermediairs bieden u een mogelijke oplossing.",
      newsId: "",
      navTo: "/article30",
    },
    {
      category: "BTW & accijns",
      publishingDate: "19.10.23",
      imageUrl: require("../../assets/NewsImage29.png"),
      headerText: "Gecompliceerde fiscale entiteitsstructuren kunnen de teruggave van Belgische accijnzen in gevaar brengen",
      bodyText:
        "Nederland staat bekend om vrij unieke fiscale structuren. Dit kan o.a. bij de Belgische diesel accijns teruggaaf leiden tot vertraagde- of afgewezen teruggaven.",
      newsId: "",
      navTo: "/article29",
    },
    {
      category: "BTW & accijns",
      publishingDate: "12.10.23",
      imageUrl: require("../../assets/NewsImage28.png"),
      headerText: "Mogelijk uitstel van Maut-uitbreiding naar 1 januari 2024 ",
      bodyText:
        "Eerder heeft de Duitse overheid het wetsvoorstel aangenomen met de intentie om de CO2-tolheffing op 1 december 2023 in te voeren. Recent nieuws suggereert echter dat deze datum mogelijk wordt uitgesteld.",
      newsId: "",
      navTo: "/article28",
    },
    {
      category: "Algemeen transport",
      publishingDate: "28.09.23",
      imageUrl: require("../../assets/NewsImage27.png"),
      headerText: "Uitbreiding van tolwegennetwerk in België",
      bodyText:
        "De Vlaamse regering heeft aangekondigd dat ze van plan is om het tolwegennet in 2024 aanzienlijk uit te breiden. Dit initiatief wordt gemotiveerd door verschillende doelstellingen, zoals...",
      newsId: "",
      navTo: "/article27",
    },
    {
      category: "BTW & accijns",
      publishingDate: "21.09.23",
      imageUrl: require("../../assets/NewsImage26.png"),
      headerText: "De CO2-tolheffing binnenkort van kracht",
      bodyText:
        "Per 1 december 2023 wordt verwacht dat een extra tolheffing wordt ingevoerd in Duitsland. Vanaf die datum wordt een heffing tot 200 euro per ton uitgestoten CO2 geïmplementeerd.",
      newsId: "",
      navTo: "/article26",
    },
    {
      category: "Algemeen transport",
      publishingDate: "14.09.23",
      imageUrl: require("../../assets/NewsImage25.png"),
      headerText: "Onterechte boetes bij gebruik van de nieuwe tachograaf ",
      bodyText:
        "De Smart Tacho 2 biedt een hoop nieuwe functionaliteiten t.o.v. zijn voorganger. Helaas hebben deze nieuwe functionaliteiten in sommige gevallen geleid tot onterechte boetes bij grensovergangen.",
      newsId: "",
      navTo: "/article25",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "07.09.23",
      imageUrl: require("../../assets/NewsImage24.png"),
      headerText: "Financiële onzekerheden en risico's door constant muterende regelgevingen",
      bodyText:
        "De overheid probeert ondernemers te stimuleren om groene investeringen te maken d.m.v. toegepaste regelingen en subsidies. Toch blijkt nu al dat de overheid niet altijd netjes hun belofte nakomt.",
      newsId: "",
      navTo: "/article24",
    },
    {
      category: "BTW & Accijns",
      publishingDate: "31.08.23",
      imageUrl: require("../../assets/NewsImage23.png"),
      headerText: "Belastingfraude zorgt voor meer complexiteit in het BTW-& accijns-teruggaafproces",
      bodyText:
        "De groeiende BTW- & accijnsfraude legt extra druk op belastingautoriteiten, transportondernemers en intermediairs. Naast dat dit het BTW- & accijns teruggaafproces vertraagt, maakt dit het ook...",
      newsId: "",
      navTo: "/article23",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "17.08.23",
      imageUrl: require("../../assets/NewsImage22.png"),
      headerText: "Bespaar tot €131.900 per aanschaf voertuig met 'AanZET'",
      bodyText:
        "Transportondernemingen die de overstap maken naar volledig emissieloze (uitstootvrije) vrachtauto kunnen tot wel €131.900 subsidie ontvangen per voertuig met de AanZET regeling.",
      newsId: "",
      navTo: "/article22",
    },
    {
      category: "Wet & regelgeving",
      publishingDate: "10.08.23",
      imageUrl: require("../../assets/NewsImage21.png"),
      headerText: "Regelingen, aftrekposten, afschrijvingen en subsidies bij 'groene transitie'",
      bodyText:
        "Transportondernemers staan voor uitdagende investeringskeuzes met de groene transitie. Met goed inzicht in regelingen, aftrekposten, afschrijvingen en subsidies kunnen ze maximaal financieel besparen op hun investeringen.",
      newsId: "",
      navTo: "/article21",
    },
    {
      category: "BTW & accijns",
      publishingDate: "27.07.23",
      imageUrl: require("../../assets/NewsImage20.png"),
      headerText: "Britse truckheffing vanaf 1 augustus weer ingevoerd",
      bodyText:
        "Om ondernemers te ondersteunen in de corona crisis werd de Britse truckheffing tijdelijk afgeschaft. Vanaf 1 augustus wordt deze heffing opnieuw opgevoerd voor Britse en buitenlandse voertuigen.",
      newsId: "",
      navTo: "/article20",
    },
    {
      category: "BTW & accijns",
      publishingDate: "20.07.23",
      imageUrl: require("../../assets/NewsImage19.png"),
      headerText: "Eerdere terugsluizing van 170 miljoen voor invoering ‘trucktol’ 2027",
      bodyText:
        "Het kabinet is bezig met plannen om de terugsluis vanuit de trucktol (wet vrachtwagenheffing) naar voren te halen. In plaats van het oorspronkelijke jaar 2027 zouden er al volgend jaar aanzienlijke bedragen beschikbaar komen.",
      newsId: "",
      navTo: "/article19",
    },
    {
      category: "BTW & accijns",
      publishingDate: "13.07.23",
      imageUrl: require("../../assets/NewsImage18.png"),
      headerText: "De veranderingen van het Britse BTW-teruggaaf proces",
      bodyText:
        "Sinds de Brexit hebben transportbedrijven die zakendoen in het Verenigd Koninkrijk te maken met meer uitdagingen op het gebied van BTW-terugvordering. Zo is het terugvorderingsproces complexer, is toegang krijgen lastiger en...",
      newsId: "",
      navTo: "/article18",
    },
    {
      category: "BTW & accijns",
      publishingDate: "06.07.23",
      imageUrl: require("../../assets/NewsImage17.png"),
      headerText: "Hoe bereid je je goed voor op informatieverzoeken?",
      bodyText:
        "Naast dat het belangrijk is om de reactietermijnen van informatieverzoeken te weten, is het ook belangrijk om te weten waarom- en hoe te reageren op de informatieverzoeken. ",
      newsId: "",
      navTo: "/article17",
    },
    {
      category: "BTW & accijns",
      publishingDate: "29.06.23",
      imageUrl: require("../../assets/NewsImage16.png"),
      headerText: "Reactietermijnen van informatieverzoeken bij BTW-teruggaaf",
      bodyText:
        "Buitenlandse belastingdiensten kunnen naar aanleiding van BTW-teruggaaf verzoeken komen met aanvullende vragen. Kom je niet optijd met de juiste reactie, dan loop je kans op vertraging- of volledige afwijzing van...",
      newsId: "",
      navTo: "/article16",
    },
    {
      category: "BTW & accijns",
      publishingDate: "22.06.23",
      imageUrl: require("../../assets/NewsImage15.png"),
      headerText: "Risico's van foutief ingevulde BTW-teruggaaf verzoeken",
      bodyText:
        "Onbedoelde fouten in teruggaaf verzoeken voor BTW- en/of accijns aangiftes kunnen grote negatieve gevolgen hebben, onder andere frequentere toezicht en controles door belastingdiensten, weigeringen tot...",
      newsId: "",
      navTo: "/article15",
    },
    {
      category: "BTW & accijns",
      publishingDate: "15.06.23",
      imageUrl: require("../../assets/NewsImage14.png"),
      headerText: "Drukte verwacht bij tankstations in Nederland",
      bodyText:
        "Eigenaren van tankstations in Nederland verwachten grote drukte tijdens de laatste dagen van Juni. Dit zal de laatste kans zijn voor chauffeurs om te tanken tegen de huidige tarieven.",
      newsId: "",
      navTo: "/article14",
    },
    {
      category: "BTW & accijns",
      publishingDate: "01.06.23",
      imageUrl: require("../../assets/NewsImage13.png"),
      headerText: "Gaat de papieren aangifte van dieselolie accijns binnenkort vervangen worden door de digitale aangifte?",
      bodyText:
        "Ook al leven we anno 2023 in een digitaal tijdperk, lijken overheden toch vast te blijven houden aan papieren aangiftes. Komt hier binnenkort verandering in?",
      newsId: "",
      navTo: "/article13",
    },
    {
      category: "BTW & accijns",
      publishingDate: "25.05.23",
      imageUrl: require("../../assets/NewsImage12.png"),
      headerText: "Tijdelijke Nederlandse accijnsverlaging in juli 23 ten einde. Over de grens tanken binnenkort weer aantrekkelijker?",
      bodyText:
        "Per 1 juli gaat de tijdelijke accijnsverlaging er in Nederland af, waardoor de kosten flink kunnen oplopen. Is het per 1 juli weer gunstiger om over de grens te tanken?",
      newsId: "",
      navTo: "/article12",
    },
    {
      category: "BTW & accijns",
      publishingDate: "17.05.23",
      imageUrl: require("../../assets/NewsImage11.png"),
      headerText: "Verhoging van dieselolieaccijns in Litouwen",
      bodyText:
        "Na de goedkeuring van het 'groene' brandstofaccijnzenpakket, zal vanaf 2025 het accijnstarief in Litouwen stijgen tot wel 10 cent per jaar. Dit heeft wellicht invloed op het tankgedrag in Litouwen en omliggende landen.",
      newsId: "",
      navTo: "/article11",
    },
    {
      category: "BTW & accijns",
      publishingDate: "11.05.23",
      imageUrl: require("../../assets/NewsImage10.png"),
      headerText: "BTW op rekeningrijden?",
      bodyText:
        "Naast onder andere België en Zweden voeren steeds meer Europese landen rekeningrijden in. Betaalt u hier BTW over en is deze terugvorderbaar?",
      newsId: "",
      navTo: "/article10",
    },
    {
      category: "BTW & accijns",
      publishingDate: "04.05.23",
      imageUrl: require("../../assets/NewsImage9.png"),
      headerText: "Minimum- en maximum BTW-tarieven in de EU",
      bodyText:
        "Hoe kan het dat de BTW-tarieven onderling verschillen per EU lidstaat? Kan elke EU lidstaat dit percentage ongelimiteerd verhogen/verlagen of zitten hier Europees bepaalde grenzen aan?",
      newsId: "",
      navTo: "/article9",
    },
    {
      category: "BTW & accijns",
      publishingDate: "26.04.23",
      imageUrl: require("../../assets/NewsImage8.png"),
      headerText:
        "Dé factuurvereisten om een soepele buitenlandse BTW teruggaaf te bevorderen",
      bodyText:
        "Aan welke factuurvereisten moet u als bedrijf voldoen om geen discussie te krijgen met de buitenlandse autoriteiten?",
      newsId: "",
      navTo: "/article8",
    },
    {
      category: "BTW & accijns",
      publishingDate: "20.04.23",
      imageUrl: require("../../assets/NewsImage7.png"),
      headerText:
        "Internationale ondernemingen lopen gemiddeld €14.000 aan BTW mis per jaar",
      bodyText:
        "Ongeveer 80% van de ondernemingen die actief zijn in de EU past BTW-teruggaaf niet correct toe, waardoor ze gemiddeld €14.000 aan BTW per jaar mislopen.",
      newsId: "",
      navTo: "/article7",
    },
    {
      category: "BTW & accijns",
      publishingDate: "13.04.23",
      imageUrl: require("../../assets/NewsImage6.png"),
      headerText: "Estland verhoogt BTW tarief naar 22%",
      bodyText:
        "Estland heeft besloten het hoge BTW tarief aan te gaan passen van (momenteel) 20% naar 22%. 2% extra inkomsten voor de Estse staat, dat is aanzienlijk!",
      newsId: "",
      navTo: "/article6",
    },
    {
      category: "BTW & accijns",
      publishingDate: "07.04.23",
      imageUrl: require("../../assets/NewsImage5.png"),
      headerText: "Minimum bedragen per BTW teruggaaf verzoek",
      bodyText:
        "Wellicht vraagt u zich wel eens af waarom u geconfronteerd wordt met bepaalde minimum bedragen per teruggaaf land.",
      newsId: "",
      navTo: "/article5",
    },
    {
      category: "BTW & accijns",
      publishingDate: "30.03.23",
      imageUrl: require("../../assets/NewsImage4.png"),
      headerText: "Toekomst van de Belgische dieselolie accijnzen vergoedingen",
      bodyText:
        "Wat is de toekomst van de vergoedingen op de Belgische dieselolie accijns?",
      newsId: "",
      navTo: "/article4",
    },
    {
      category: "BTW & accijns",
      publishingDate: "23.03.23",
      imageUrl: require("../../assets/NewsImage3.png"),
      headerText:
        "Belgische Accijns Compensatie (Professionele Diesel), hoe wordt dat berekend?",
      bodyText:
        "Op willekeurige tijdstippen kondigt de Belgische overheid via het Belgisch staatsblad aan dat het compensatiebedrag van de Belgische dieselolie accijns verandert. Hoe zit dat nou...",
      newsId: "",
      navTo: "/article3",
    },
    {
      category: "BTW & accijns",
      publishingDate: "17.03.23",
      imageUrl: require("../../assets/NewsImage2.png"),
      headerText: "Achtergrond van accijnsvergoedingen",
      bodyText:
        "Is het nog interessant om in België te tanken en hoe werkt het cliquet systeem in België nou precies? We zitten in de EU met 26 andere lidstaten. Toch compenseert niet iedere lidstaat...",
      newsId: "",
      navTo: "/article2",
    },
    {
      category: "Algemeen transport",
      publishingDate: "01.03.23",
      imageUrl: require("../../assets/NewsImage1.png"),
      headerText:
        "Vernieuwde duurzame benzine & Diesel nu verkrijgbaar in Duitsland",
      bodyText:
        "Er zijn twee nieuwe emissie verlagende brandstoffen recent op de markt gebracht door Aral. Het gaat hier om Aral Futura Super 95 en Aral Futura Diesel...",
      newsId: "",
      navTo: "/article1",
    },
    // {
    //   category: "Algemeen transport",
    //   publishingDate: "202302104",
    //   imageUrl: require("../../assets/NewsImage2.png"),
    //   headerText: "Levenswaardering van truckers stijgt met 10%",
    //   bodyText:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    //   newsId: "",
    // },
    // {
    //   category: "BTW & accijns",
    //   publishingDate: "20230117",
    //   imageUrl: require("../../assets/NewsImage3.png"),
    //   headerText: "Accijns compensatie België stijgt met €0,148",
    //   bodyText:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    //   newsId: "",
    // },
    // {
    //   category: "Delta nieuws",
    //   publishingDate: "20230212",
    //   imageUrl: require("../../assets/NewsImage4.png"),
    //   headerText: "Delta introduceert Delta Hybrid",
    //   bodyText:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    //   newsId: "",
    // },
  ]);

  const categories = [
    {
      name: "Alles weergeven",
    },
    {
      name: "Algemeen transport",
    },
    {
      name: "BTW & accijns",
    },
    {
      name: "Delta nieuws",
    },
    {
      name: "Wet & regelgeving",
    },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (name) => {
    console.log(name);
    if (typeof name === "object") {
      setSelectedCategory("Alles weergeven");
      setAnchorElUser(null);
      return;
    }

    setSelectedCategory(name);
    // alert(selectedCategory);
    setAnchorElUser(null);
  };

  // const handleOpenSearchBox = () => {
  //   setToggleSearchBox(!toggleSearchBox);
  // };

  // const handleCloseSearchBox = () => {
  //   setToggleSearchBox(!toggleSearchBox);
  // };

  return (
    <Container maxWidth="100%" sx={{ m: 0, p: 0 }}>
      <Container maxWidth="xl" sx={{ mb: 10 }}>
        <Divider />
        <>
          <Stack direction="row">
            {/* <Box
              sx={{
                ml: 10,
              }}
            >
              <Menu
                sx={{ mt: "30px", borderRadius: "0px" }}
                id="Producten"
                anchorEl={anchorElUser}
                anchorOrigin={
                  {
                    // vertical: "top",
                    // horizontal: "left",
                  }
                }
                keepMounted
                transformOrigin={
                  {
                    // vertical: "top",
                    // horizontal: "left",
                  }
                }
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {categories.map((category) => (
                  <MenuItem
                    sx={{
                      color:
                        selectedCategory === category.name
                          ? "#2b5baa"
                          : "inherit",
                    }}
                    onClick={() => handleCloseUserMenu(category.name)}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Menu>
              <Typography
                onClick={handleOpenSearchBox}
                sx={{
                  // my: 2,
                  // ml: 1,
                  // mr: 1,
                  cursor: "pointer",
                  color: "#2b5baa",
                  display: "block",
                  // textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "HelveticaNeue-Light",
                  textTransform: "none",
                  textDecoration: "none",
                  "a:visited": {
                    color: "#FFFFFF",
                  },
                  a: {
                    textDecoration: "none",
                    color: "#FFFFFF",
                  },
                }}
              >
                <div>
                  <span style={{ fontSize: "16px" }}>
                    <TfiSearch />
                  </span>{" "}
                  Search
                </div>
              </Typography>
            </Box> */}
            <Box
              sx={{
                ml: 10,
              }}
            >
              <Menu
                sx={{ mt: "30px", borderRadius: "0px" }}
                id="Producten"
                anchorEl={anchorElUser}
                anchorOrigin={
                  {
                    // vertical: "top",
                    // horizontal: "left",
                  }
                }
                keepMounted
                transformOrigin={
                  {
                    // vertical: "top",
                    // horizontal: "left",
                  }
                }
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {categories.map((category) => (
                  <MenuItem
                    sx={{
                      color:
                        selectedCategory === category.name
                          ? "#2b5baa"
                          : "inherit",
                    }}
                    onClick={() => handleCloseUserMenu(category.name)}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Menu>
              <Typography
                onClick={handleOpenUserMenu}
                sx={{
                  // my: 2,
                  // ml: 1,
                  // mr: 1,
                  cursor: "pointer",
                  color: "#2b5baa",
                  display: "block",
                  // textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "HelveticaNeue-Light",
                  textTransform: "none",
                  textDecoration: "none",
                  "a:visited": {
                    color: "#FFFFFF",
                  },
                  a: {
                    textDecoration: "none",
                    color: "#FFFFFF",
                  },
                }}
              >
                <div>
                  Category
                  <FiChevronDown />
                </div>
              </Typography>
            </Box>
          </Stack>
        </>
        <Divider />
        <Grid container spacing={5} mt={1}>
          <NewsCard newsItems={newsItems} selectedCategory={selectedCategory} />
        </Grid>
      </Container>
    </Container>
  );
};

export default NewsGrid;
